import { distinctUntilChanged, filter, lastValueFrom, merge, of, switchMap, tap } from 'rxjs';

import { DOCUMENT } from '@angular/common';
import { Injectable, afterNextRender, inject } from '@angular/core';

import { MemoizedObservable } from '@cgib/shared/helper/memoized-observable';

import { QuotaRepository } from '../repositories/quota.repository';
import { UserRepository } from '../repositories/user.repository';
import { QuotaGraphqlService } from './graphql/graphql-quota.service';
import { UserGraphqlService } from './graphql/graphql-user.service';

@Injectable({
    providedIn: 'root'
})
export class QuotaService {
    private readonly graphqlService = inject(QuotaGraphqlService);
    private readonly graphqlUserService = inject(UserGraphqlService);
    private readonly userRepository = inject(UserRepository);
    private readonly quotaRepository = inject(QuotaRepository);
    private readonly document = inject(DOCUMENT);

    constructor() {
        // fetch user quota once the user signs in
        this.userRepository
            .isAuthenticated$()
            .pipe(
                filter(authenticated => !!authenticated),
                distinctUntilChanged(),
                tap(() => this.updateQuota())
            )
            .subscribe();
        afterNextRender(() => {
            if (!this.document.location.hostname.includes('admin')) {
                // Subscribe to user quota updates
                this.userRepository
                    .isAuthenticated$()
                    .pipe(
                        switchMap(authenticated => {
                            if (authenticated) {
                                return merge(
                                    this.graphqlService.subscribeToQuotaUpdate$(),
                                    this.graphqlUserService.getUserQuota$()
                                );
                            } else {
                                this.quotaRepository.resetQuota();
                                return of(undefined);
                            }
                        }),
                        filter(data => !!data),
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        tap(data => this.quotaRepository.setQuota(data!))
                    )
                    .subscribe();
            }
        });
    }

    @MemoizedObservable()
    getSubscriptionPriceId$() {
        return this.quotaRepository.getSubscriptionPriceId$();
    }

    @MemoizedObservable()
    getSubscriptionProductId$() {
        return this.quotaRepository.getSubscriptionProductId$();
    }

    @MemoizedObservable()
    hasSubscription$() {
        return this.quotaRepository.hasSubscription$();
    }

    @MemoizedObservable()
    hasActiveSubscription$() {
        return this.quotaRepository.hasActiveSubscription$();
    }

    @MemoizedObservable()
    getQuota$() {
        return this.quotaRepository.getQuota$();
    }

    private async updateQuota() {
        return lastValueFrom(
            this.graphqlUserService
                .getUserQuota$()
                .pipe(tap(quotaDetails => this.quotaRepository.setQuota(quotaDetails)))
        );
    }
}
