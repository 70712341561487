import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';

import { LibraryItemDto } from '@cgib/shared/dtos/library-item/library-item.dto';
import { PaginationResult } from '@cgib/shared/dtos/pagination-result.dto';
import { QuotaDto } from '@cgib/shared/dtos/subscription/quota.dto';

import { BaseGraphqlService } from './base-graphql.service';
import { GET_LIBRARY_ITEMS_HISTORY } from './queries';
import { QUOTA_UPDATE_SUBSCRIPTION } from './subscription';

@Injectable({
    providedIn: 'root'
})
export class QuotaGraphqlService extends BaseGraphqlService {
    subscribeToQuotaUpdate$(): Observable<QuotaDto | undefined> {
        return this.authorizedSubscription$<{ activeQuotaUpdate: QuotaDto }>({
            query: QUOTA_UPDATE_SUBSCRIPTION,
            variables: {},
            headers: {
                'x-client-id': this.config.cgibClientId
            }
        }).pipe(map(result => result?.activeQuotaUpdate));
    }

    getLibraryItems$(page: number, size: number) {
        return this.authorizedQuery$<{ getLibraryItemList: PaginationResult<LibraryItemDto> }>({
            query: GET_LIBRARY_ITEMS_HISTORY,
            variables: {
                pagination: { page, size }
            },
            headers: {
                'x-client-id': this.config.cgibClientId
            }
        }).pipe(map(result => result.getLibraryItemList));
    }
}
